import React, { useEffect } from 'react';

const MaxBountyScript = () => {
  useEffect(() => {
    // Initialize MaxBounty only once when component mounts
    const initMaxBounty = () => {
      console.log("%c Line:7 🥤 ===initMaxBounty===", "color:#4fff4B");
      if (!window.mbc) {
        const script = document.createElement('script');
        script.src = 'https://mb1-cdn.com/lib/mb-lib.min.js';
        script.async = true;
        script.onload = () => {
          // Initialize after script is loaded
          window.mbc && window.mbc('init', 14681);
        };
        document.head.appendChild(script);
      }
    };

    initMaxBounty();

    // Cleanup function
    return () => {
      // Optional: Clean up if needed when component unmounts
      const script = document.querySelector('script[src="https://mb1-cdn.com/lib/mb-lib.min.js"]');
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null;
};

export default MaxBountyScript;
