import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
// import './polyfill'
import React from 'react';
import { Provider } from 'react-redux';
import store from './state/store';
import MaxBountyScript from './components/MaxBountyScript';

export default ({ element }) => {
  return <Provider store={store}>
      {element}
      <MaxBountyScript />
    </Provider>
}
